.App {
  text-align: center;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: animateOpacity 10s ease-in-out infinite;
}

.App-header {
  /* background-color: #282c34; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.App-footer {
  position: fixed;
  left: 20px;
  right: 20px;
  bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App-link {
  color: #61dafb;
  text-decoration: none;
}

@keyframes animateOpacity {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}
